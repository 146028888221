import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { mfaCompleteSetup, mfaInitSetup } from '../../api/auth'
import ConfirmationButton from '../../components/buttons/confirmation-button'
import SemanticTextFormField from '../../components/form/text-field'
import { HttpStatusCode } from '../../interfaces/status-codes'
import { showErrorToast, showToast } from '../../components/toast'
import SemanticLoader from '../../components/loader'


export default function SetupMFAPage() {
  const [base64Image, setBase64Image] = useState('')
  const [oneTimePassword, setOneTimePassword] = useState('')
  const [mfaActivated, setMfaActivated] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    mfaInitSetup().then(res => setBase64Image(res.data.qrcode_data))
  }, [])

  async function activateMfaApiCall() {
    const { status, data } = await mfaCompleteSetup(oneTimePassword)

    if (status === HttpStatusCode.CREATED) {
      setMfaActivated(true)
      showToast({ message: 'MFA activated successfully!' })
    } else {
      showErrorToast(data?.message ?? 'Could not activate MFA. Make sure your one-time password is correct and try again')
      setOneTimePassword('')
    }
  }

  return <>
    <div style={{ maxWidth: '700px', margin: '8px auto' }}>
      <h1>Activate MFA</h1>
      {
        mfaActivated ? <>
          <h4>MFA activated! The next time you login you will be required to use this one-time password after the username/password is validated</h4>
          <ConfirmationButton text='Go to home' onClick={() => navigate('/home')} />
        </> : <>
          <div>
            <h4>1: Scan the following QR code with your Microsoft Authenticator app. It should automatically add InfiniteSystem to your list of apps</h4>
            <div>
              {
                base64Image ? <>
                  <img src={base64Image} alt='QR Code for Microsoft Authenticator' width={300} height={300} style={{ display: 'block', margin: 'auto' }} />
                </> : <><div style={{ padding: '4px' }}><SemanticLoader /></div></>
              }
            </div>
          </div>
          <div>
            <h4>2: Use your 6 digits generated code to activate MFA</h4>
            <div>
              <div style={{ maxWidth: '400px' }}>
                <SemanticTextFormField index={0} value={oneTimePassword} label={'Type the one-time password displayed in your authenticator app'} placeholder={'One-time password...'}
                  onChange={(event) => setOneTimePassword(event.target.value)} />
              </div>
              <div style={{ paddingTop: '8px' }}>
                <ConfirmationButton text='Activate MFA' onClick={activateMfaApiCall} disabled={mfaActivated} />
              </div>
            </div>
          </div>
        </>
      }
    </div>
  </>
}
