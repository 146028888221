import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import SemanticTextFormField from '../../components/form/text-field'
import ConfirmationButton from '../../components/buttons/confirmation-button'
import { mfaLogin } from '../../api/auth'
import { HttpStatusCode } from '../../interfaces/status-codes'
import { useAuth } from '../../hooks/auth'
import { showErrorToast } from '../../components/toast'


export default function MfaLoginPage() {
  const navigate = useNavigate()
  const { onLogin } = useAuth()
  const { state } = useLocation()
  const [oneTimePassword, setOneTimePassword] = useState('')

  async function verify(oneTimePassword: string) {
    const { status, data } = await mfaLogin(oneTimePassword, state)

    if(status === HttpStatusCode.OK) {
      onLogin(data.access)

      navigate('/home')
    } else {
      showErrorToast(data?.message ?? 'Invalid One-time password')
      setOneTimePassword('')
    }
  }

  return <>
    <div style={{ maxWidth: '300px', margin: '0px auto', padding: '24px' }}>
      <div style={{ paddingTop: '16px' }}>
      </div>
      <SemanticTextFormField index={0} value={oneTimePassword} label={'Type the one-time password displayed in your authenticator app'} placeholder={'One-time password...'}
        onChange={(event) => setOneTimePassword(event.target.value)} />
      <div style={{ paddingTop: '8px' }}>
        <ConfirmationButton text='Verify' onClick={() => verify(oneTimePassword)} />
      </div>
    </div>
  </>
}
