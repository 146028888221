import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import { SemanticButton } from '../../../components/buttons/buttons'
import CasesSearch from '../../../components/cases-search'
import PinnedItems from '../pinned-items'
import TransfersPendingAcceptance from './transfers-pending-acceptance'
import Assignments from './assignments'
import RecentCases from './recent-cases'
import SidebarWrapperForPage from '../../../components/sidebar-wrapper-for-page'
import HomePageGuides from '../../../guides/home/case-based'
import Hint from '../../../components/hint'
import { getEntities } from '../../../api/entity'
import { useEffect, useState } from 'react'
import { Entity } from '../../../interfaces/all'
import { getIsMfaSetupCompleted } from '../../../api/me'
import ConfirmationButton from '../../../components/buttons/confirmation-button'


export default function CaseBasedHomePage() {
  const navigate = useNavigate()
  const [entities, setEntities] = useState<Entity[] | undefined>([])
  const [isMfaSetupCompleted, setIsMfaSetupCompleted] = useState<boolean | undefined>()

  useEffect(() => {
    getEntities().then(res => setEntities(res.data))

    getIsMfaSetupCompleted().then(res => setIsMfaSetupCompleted(res))
  }, [])

  const isThereAnyEntity = entities && entities.length > 0
  const isThereAnyCaseDataTypeEntity = entities?.some(x => x.data_type === 'Case')

  const pageContent = <>
    <div style={{ padding: '8px', height: '100%' }}>
      <Grid columns={3} divided>
        <Grid.Row>
          <Grid.Column>
            <div>
              {
                !isThereAnyEntity &&
                <Hint text={'Hint: Start by building new data structures (Entity) in the Admin Hub in the menu above'} />
              }
              {
                isThereAnyEntity && !isThereAnyCaseDataTypeEntity &&
                <Hint text={'Hint: Start by creating a new Case Type (Entity) in the Admin Hub in the menu above'} />
              }
              {
                isMfaSetupCompleted === false && <>
                  <div style={{ display: 'flex', padding: '12px'}}>
                    <Hint text='Protect your account by setting up MFA (multi-factor authentication) using Microsoft (or any other) authenticator app.'></Hint>
                    <ConfirmationButton text='Setup MFA' onClick={() => navigate('/mfa-setup')} />
                  </div>
                </>
              }
              <div style={{ margin: '0px', textAlign: 'center', display: 'flex' }}>
                <div style={{ margin: '0 12px' }}>
                  <SemanticButton onClick={() => navigate('/cases')} text='All cases' disabled={!isThereAnyCaseDataTypeEntity} />
                </div>
                <div style={{ margin: '0 12px' }}>
                  <SemanticButton onClick={() => navigate('/cases/create')} text='Create case' disabled={!isThereAnyCaseDataTypeEntity} />
                </div>
                <div style={{ margin: '0 12px' }}>
                  <SemanticButton onClick={() => navigate('/evidences')} text='All evidences' disabled={!isThereAnyCaseDataTypeEntity} />
                </div>
                <div style={{ margin: '0 12px' }}>
                  <SemanticButton onClick={() => navigate('/services')} text='All services' disabled={!isThereAnyCaseDataTypeEntity} />
                </div>
              </div>
              <div style={{ margin: '10px' }}>
                <CasesSearch selectedCaseId='' />
              </div>
              <div style={{ margin: '10px' }}>
                <RecentCases />
              </div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <Assignments />
            <TransfersPendingAcceptance />
          </Grid.Column>
          <Grid.Column>
            <PinnedItems />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div style={{ textAlign: 'center' }}>
        <br />
        <br />
        <iframe width="400" height="240"
          src={'https://www.youtube.com/embed/H7VKYf7144A'}
          title="Infinite System Home Page"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen></iframe>
      </div>
    </div>

  </>


  return <SidebarWrapperForPage page={pageContent} sidebar={<HomePageGuides />} />
}
