import { AxiosResponse } from 'axios'
import { loginRequest, request, mfaLoginRequest } from './common'

export const login = async (username: string, password: string) : Promise<Response> => {
  return loginRequest(JSON.stringify({ username, password })) 
}

export const mfaInitSetup = async ():  Promise<AxiosResponse> => {
  return request({
    method: 'POST',
    path: 'auth/mfa-init-setup'
  })
}

export const mfaLogin = async (oneTimePassword: string, otpToken: string):  Promise<AxiosResponse> => {
  return mfaLoginRequest(oneTimePassword, otpToken)
}

export const mfaCompleteSetup = async(oneTimePassword: string): Promise<AxiosResponse> => {
  return request({
    method: 'POST',
    path: 'auth/mfa-complete-setup',
    body: {
      'one_time_password': oneTimePassword
    }
  })
}
