import { Container } from 'semantic-ui-react'

import SemanticIcon from './icons/icon'

interface HintProps {
  text: string
}
export default function Hint(props: HintProps) {
  return <><Container>
    <SemanticIcon name={'exclamation circle'} color={'yellow'} overrideStyle={{ cursor: 'default'}}/>
    {props.text}
  </Container><br /></>
}
