import { useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'

import { getIsMfaSetupCompleted, getProfile, updateProfile } from '../api/me'
import { Profile } from '../interfaces/api/me'
import SemanticLoader from '../components/loader'
import ConfirmationButton from '../components/buttons/confirmation-button'
import CancellationButton from '../components/buttons/cancelation-button'
import { showErrorToast, showToast } from '../components/toast'
import { HttpStatusCode } from '../interfaces/status-codes'
import Hint from '../components/hint'
import IconWithText from '../components/icon-with-text'


export default function ProfilePage() {
  const [profile, setProfile] = useState<Profile | undefined>()
  const [loading, setLoading] = useState(true)
  const [isMfaSetupCompleted, setIsMfaSetupCompleted] = useState<boolean | undefined>()
  const navigate = useNavigate()

  useEffect(() => {
    getProfile().then(response => {
      setProfile(response.data)
      setLoading(false)
    })

    getIsMfaSetupCompleted().then(res => setIsMfaSetupCompleted(res))
  }, [])

  async function reloadProfile() {
    setLoading(true)

    getProfile().then(response => {
      setProfile(response.data)
      setLoading(false)
    })
  }

  async function onSave() {
    if (!(profile && profile.pin.length >= 4)) {
      showErrorToast('Min pin size is 4 digits')
      return
    }

    setLoading(true)

    const { status, data } = await updateProfile({ barcode: profile?.barcode, pin: profile?.pin })

    if (status === HttpStatusCode.OK) {
      showToast({ message: 'Profile updated!' })
    } else {
      showErrorToast(data?.message ?? 'Profile not updated')
    }

    reloadProfile()
  }

  return (
    <>
      <>
        <div style={{ maxWidth: '300px', margin: '0px auto', padding: '8px' }}>
          <h2>Profile</h2>
          {
            !loading ?
              profile && <>
                <h3>{`Username: ${profile.username}`}</h3>

                <div>
                  <Form>
                    <Form.Input fluid label='Barcode' placeholder='barcode ...' value={profile.barcode} onChange={(e) => setProfile({
                      ...profile,
                      barcode: e.target.value
                    })} />
                    <Form.Input fluid label='Pin' placeholder='pin ...' value={profile.pin} type='password' required onChange={(e) => setProfile({
                      ...profile,
                      pin: e.target.value
                    })} />
                    <CancellationButton text='Cancel' onClick={reloadProfile} />
                    <ConfirmationButton text='Save' onClick={onSave} />
                  </Form>
                </div>
              </>
              : <SemanticLoader />
          }
        </div>
        <div style={{ maxWidth: '300px', margin: '0px auto', padding: '16px' }}>
          {
            isMfaSetupCompleted === false ?
              <>
                <Hint text='Protect your account by setting up MFA (multi-factor authentication) using Microsoft (or any other) authenticator app.'></Hint>
                <ConfirmationButton text='Setup MFA' onClick={() => navigate('/mfa-setup')} />
              </> :
              <>
                <IconWithText text={'MFA is activated for this account'} iconProps={{color: 'green', name: 'check'}} />
              </>
          }
        </div>

      </>
    </>
  )
}
